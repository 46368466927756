// Import packages
import React, { Component } from "react";

//Import styles
import "../assets/styles/front-styles/front-layout.scss";

//Import images
import Logo from "../assets/images/front/bg-image-v2.jpeg";
import ResponsiveBeer from "../assets/images/front/bg-image-mobile-v2.jpeg";
// import Logo from "../assets/images/front/bgImg_ic.jpg";
// import ResponsiveBeer from "../assets/images/front/responsiveBeer_ic.png";
// import ResponsiveImage from "../assets/images/front/responsiveImage_ic.png";

//Import components

import { InputGroup } from "../components/uiElements/inputGroup";
import MessageModal from "./MessageModal"

// Import utils
import {
    SendPromoCode,
    GetTranslations,
} from "../requests/api";

class FrontLayout extends Component {
    constructor (props) {
        super(props);
        this.state = {
            user: {
                firstName: '',
                lastName: '',
                phone: '',
                promoCode: '',
                checked: false,
            },
            modalOpen: false,
            errors: {
                firstName: false,
                lastName: false,
                phone: false,
                promoCode: false,
                checked: false,
            },
            errorSlug: '',
            requestLoading: false,
            promoCodeResponse: null,
            xRateLimitReset: null,

            translations: {},
        };
        this.requested = false

        this.getInputValues = this.getInputValues.bind(this);
        this.getCheckBoxValue = this.getCheckBoxValue.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.sendPromoCode = this.sendPromoCode.bind(this);
    }

    componentDidMount () {
        GetTranslations().then(translations => {
            translations && this.setState({
                translations: translations.hy || {}
            })
        });
    }

    getInputValues (e) {
        const { name, value } = e;
        let regexpNumber = /^\d*$/;
        if (name === 'phone' && value !== '') {
            if (regexpNumber.test(value)) {
                this.setState({
                    user: {
                        ...this.state.user,
                        [name]: value,
                    }
                })
            }
        } else this.setState({
            user: {
                ...this.state.user,
                [name]: value,
            }
        })
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: false,
            },
        })
    }

    getCheckBoxValue (e) {
        this.setState({
            user: {
                ...this.state.user,
                checked: e.target.checked,
            },
            errors: {
                ...this.state.errors,
                checked: false,
            },
        })
    }

    closeModal (resetData=true) {
        this.requested = false
        if(resetData){
            this.setState({
                user: {
                    firstName: '',
                    lastName: '',
                    phone: '',
                    promoCode: '',
                    checked: false,
                },
                errors: {
                    firstName: false,
                    lastName: false,
                    phone: false,
                    promoCode: false,
                    checked: false,
                },
                modalOpen: false,
                requestLoading: false,
                promoCodeResponse: null,
                xRateLimitReset: null,
                errorSlug: ''
            });
        }else {
            this.setState({
                modalOpen: false,
                requestLoading: false,
                promoCodeResponse: null,
                xRateLimitReset: null,
                errorSlug: ''
            });
        }
    }


    async sendPromoCode () {
        const { user } = this.state;
        if (this.requested) {
            return;
        }
        this.requested = true;
        const errors = {
            phone: false,
            promoCode: false,
            checked: false,
        };
        let allFieldsIsValid = true;

        let regexPhone = new RegExp("^[0-9]{8}$");

        Object.keys(errors).forEach((key) => {
            if (!user[key] || (key === 'phone' && !regexPhone.test(user.phone))) {
                errors[key] = true;
                allFieldsIsValid = false;
            }
        });

        if (user?.promoCode && user.promoCode.length !== 5 && user.promoCode.length !== 7) {
            errors.promoCode = true;
            allFieldsIsValid = false;
        }

        if (!user?.checked) {
            errors.checked = true;
            allFieldsIsValid = false;
        }

        if (allFieldsIsValid) {
            this.setState({
                requestLoading: true
            })
            const reqData = {
                phone: '+374' + user.phone,
                promoCode: user.promoCode,
                firstName: user.firstName,
                lastName: user.lastName,
            };

            SendPromoCode(reqData).then(({ data }) => {
                this.setState({
                    user: {
                        firstName: '',
                        lastName: '',
                        phone: '',
                        promoCode: '',
                        checked: false,
                    },
                    errors: {
                        firstName: false,
                        lastName: false,
                        phone: false,
                        promoCode: false,
                        checked: false,
                    },
                    modalOpen: true,
                    requestLoading: false,
                    promoCodeResponse: data
                });
            }).catch((error) => {
                const _errorSlug =error?.response?.data?.status === 400 ? 'CODE_VALIDATION':   "UNKNOWN"
                this.setState({
                    errorSlug: error?.response?.data?.slug || _errorSlug,
                    xRateLimitReset: error?.response?.headers?.['x-ratelimit-reset'],
                    modalOpen: true,
                });
            }).finally(() => {
                this.requested = false;
            })
        } else {
            this.requested = false
            this.setState({
                errors,
            });
        }
    }

    render () {
        const {
            user,
            errors,
            modalOpen,
            errorSlug,
            requestLoading,
            translations,
            promoCodeResponse,
            xRateLimitReset
        } = this.state;

        return <div className={'front-layout-wrapper'}>

            <div className="info-content">
                <img src={Logo} className="bgImg" alt=""/>
                <img src={ResponsiveBeer} className={'responsiveBeer'} alt=""/>
                <div className={'header'}>
                    <h1 className="page-title">
                        {translations && translations['page-title']}
                    </h1>
                    <p className="page-description">
                        {translations && translations['page-description']} <span>Եգիպտոս</span>  և բազում այլ նվերներ
                    </p>
                </div>
                <div className="form-wrapper">
                    <div className={'row'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            name={'firstName'}
                            placeholder={translations && translations['name-input-placeholder']}
                            value={user.firstName}
                            error={errors.firstName}
                            label={translations && translations['name-input-label']?.toUpperCase()}
                            maxLength={50}
                            onChange={this.getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            name={'lastName'}
                            placeholder={translations && translations['last-name-input-placeholder']}
                            value={user.lastName}
                            error={errors.lastName}
                            label={translations && translations['last-name-input-label']?.toUpperCase()}
                            maxLength={50}
                            onChange={this.getInputValues}
                        />
                    </div>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'phone'}
                        className={'tell-input'}
                        value={user.phone}
                        error={errors.phone}
                        label={<span><span
                            className="code">+374</span>{translations && translations['phone-input-placeholder']?.toUpperCase() + '*'}</span>}
                        maxLength={8}
                        onChange={this.getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'promoCode'}
                        placeholder={translations && translations['code-input-placeholder']}
                        value={user.promoCode}
                        error={errors?.promoCode}
                        label={translations && translations['code-input-label']?.toUpperCase() + '*'}
                        maxLength={7}
                        onChange={this.getInputValues}
                    />

                    <button className='send-button'
                            onClick={() => {
                                if (!requestLoading && this.sendPromoCode) {
                                    this.sendPromoCode();
                                }
                            }}>
                        {translations && translations['sent-button-text']?.toUpperCase()}
                    </button>
                    <div className={`checkbox ${errors.checked ? 'checked-error' : ''}`}>
                        <input type="checkbox" name="interest" id="checkbox_id" checked={user?.checked} onChange={this.getCheckBoxValue}/>
                        <label htmlFor="years" for="checkbox_id" >{translations && translations['code-for-18-year-olds']}</label>
                    </div>

                    <div className={'sms-block'}>
                        <span className={'sms-block-number'}>
                            Կամ կարող եք ուղարկել SMS <p>8707</p> համարին
                        </span>
                        <span className={'sms-block-price'}>
                            {translations && translations['sms-block-price-text']}
                        </span>
                    </div>
                </div>
                {/*<img src={ResponsiveImage} className={'responsiveImage'} alt=""/>*/}
            </div>
            <MessageModal closeModal={this.closeModal}
                          showModal={modalOpen}
                          translations={translations}
                          errorSlug={errorSlug}
                          promoCodeResponse={promoCodeResponse}
                          xRateLimitReset={xRateLimitReset}
            />

        </div>

    }
}

export default FrontLayout;

import React from "react";

import "../../assets/styles/dataDisplay/inputGroup.scss";

export function InputGroup(props) {
    const {
        label, type, inputType, name, placeholder, error, options, autocomplete = "on", initValue = '',
        defValue, step, value, minValue, maxValue, maxLength, autoFocus, resizable, regExp, onKeyDown,
        className,disabled
    } = props;

    function onChange(e) {
        let {name, value} = e.target;
        value = value.trimStart();
        if (regExp && value && !regExp.test(value)) {
            return;
        }
        props.onChange({
            name,
            value,
            haveChanges: (!initValue && value) || initValue?.toString() !== value?.toString()
        });
    }

    return <div className={`input-group ${className}`}>

        {label && <label>{label}</label>}

        {inputType === "input" && type !== "number" && type !== "checkbox" && type !== "password" &&
        <>
            <input type={type} name={name} placeholder={placeholder} maxLength={maxLength}
                   value={value || ''} defaultValue={defValue} onChange={onChange}
                   autoFocus={autoFocus} onKeyDown={onKeyDown} disabled={!!disabled}
                   className={`${error ? "invalid" : ""}`}/>
            {props.children}
        </>}

        {inputType === "input" && type === "password" &&
        <> <input type={type} name={name} placeholder={placeholder} maxLength={maxLength}
                  value={value || ''} defaultValue={defValue} onChange={onChange}
                  autoFocus={autoFocus} autoComplete={autocomplete} disabled={!!disabled}
                  className={`${error ? "invalid" : ""}`}/>
            {props.children}
        </>}

        {inputType === "input" && type === "number" &&
        <input type={type} name={name} value={value || ''} min={minValue} max={maxValue}
               step={step} onChange={onChange} disabled={!!disabled}
               className={`${error ? "invalid" : ""}`}/>}

        {inputType === "input" && type === "checkbox" &&
        <input type={type} name={name} checked={value} onChange={onChange}/>}

        {inputType === "textarea" &&
        <textarea name={name} placeholder={placeholder} value={value || ''}
                  defaultValue={defValue} onChange={onChange} maxLength={maxLength}
                  className={`${error ? "invalid" : ""} ${resizable ? "resizable" : ""}`}/>}

        {inputType === "select" &&
        <select value={value || ''} name={name} placeholder={placeholder} onChange={onChange}
                className={`${error ? "invalid" : ""}`}>
            {options && !!options.length && options.map((item, index) => {
                return <option value={item.value} key={index}>{item.title}</option>
            })}
        </select>}

        {inputType === "wrapper" &&
        <> {props.children}</>
        }
    </div>
}

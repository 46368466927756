// Import packages
import React from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";

// Import utils
import {history} from "../configs/history";

// Import pages
import FrontLayout from "../front-containers/FrontLayout";


export default () => {
    return <Router history={history}>
            <Switch>
                <Route exact path="/" component={FrontLayout}/>
                <Redirect to="/"/>
            </Switch>
    </Router>
}

import { _urlStaticTexts, _urlLanguagesWeb, _urlPromoCodeWeb } from "../config/urls";
import { requestWeb } from "../config/request";

export const GetLanguagesWeb = () => {
    const requestData = {
        url: _urlLanguagesWeb,
        method: "GET",
    };
    return requestWeb(requestData)
        .then(async(res) => {
            return res.data;
        })
};

export const SendPromoCode = (data) => {
    const requestData = {
        url: `${_urlPromoCodeWeb}`,
        method: "POST",
        data: data,
    };

    return requestWeb(requestData)
};

export const GetTranslations = () => {
    const requestData = {
        url: `${_urlStaticTexts}`,
        method: "GET",
    };
    return requestWeb(requestData)
        .then(res => {
            return res.data
        })
};

// Import packages
import React from "react";
import Modal from "react-modal";

//Import styles
import "../assets/styles/front-styles/message-modal.scss";

//Import images


//Import Components
import { ReactComponent as ErrorLogo } from "../assets/images/front/error_ic.svg";
import { ReactComponent as RightLogo } from "../assets/images/front/right_ic.svg";
import { ReactComponent as CloseIcon } from "../assets/images/front/icons/ic_clear.svg";

//Import Utils

function MessageModal (props) {
    function closeModal () {
        let reset= true;
        if( props?.errorSlug === 'PROMO_CODE_NOT_FOUND'|| props?.errorSlug === 'UNKNOWN' ){
            reset =false
        }
        props.closeModal(reset)
    }

    function limitTime () {
        let timeSeconds =props?.xRateLimitReset// props?.promoCodeWeb?.headers?.['x-ratelimit-reset'];
        // console.log('limit timeSeconds ', timeSeconds, ' -> ', new Date(timeSeconds * 1000))
        let todayMilliseconds = Date.now();
        // console.log('todayMilliseconds ', todayMilliseconds, ' -> ', new Date(todayMilliseconds))
        let difference = (timeSeconds * 1000) - todayMilliseconds;
        let hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        minutes = minutes < 1 ? 1 : minutes
        let time = difference > 0 ?  `${hours > 0 ? hours+' Ժամ': '' } ${minutes < 10 ? "0" + minutes : minutes}` : ""
        // const time = Math.ceil(difference / 60000);
        // console.log(time, 'time');
        return time
    }
    const rootElement = document.getElementById("root")
    return <Modal
        isOpen={props.showModal}
        onRequestClose={closeModal}
        appElement={rootElement}
        className="alert-modal"
        closeTimeoutMS={150}
        overlayClassName="alert-modal-overlay"
    >
        <div className="success-message-modal-content">
            <div className={'modal-header-block'}>
                {
                    props.promoCodeResponse?.status === 'WIN_PRIZE' ? <div className={'state-icon'}>
                        <RightLogo/>
                    </div> :  <ErrorLogo/>
                }
                <button className="modal-close-btn" onClick={closeModal}>
                    <CloseIcon className="modal-close-icon" title={''}/>
                </button>
            </div>


            {props.promoCodeResponse?.status === 'WIN_PRIZE' && <>
                <div className={'modal-title'}>
                    {props.translations && props?.translations['modal-message-text-start']}
                </div>
                <div className="modal-text">
                    {props?.promoCodeResponse?.message}
                </div>
            </>}
            {props.promoCodeResponse?.status === 'PRIZE_OVER' && <>
                <div className={'modal-title'}>
                    {props.translations && props?.translations['prize-over']}
                </div>
                <div className="modal-text">
                    {props?.promoCodeResponse?.message}
                </div>
            </>}

            {props?.promoCodeResponse?.status === 'CODE_IS_NOT_WINNER' && <>
                <div className={'modal-title'}>
                    {props.translations && props?.translations['modal-code-is-not-winner-title']}
                </div>
                <div className="modal-text">
                    {props.translations && props?.translations['code-is-not-winner']}
                </div>
            </>}

            {props?.errorSlug === 'PROMO_CODE_NOT_FOUND'  && <>
                <div className={'modal-title'}>
                    {(props.translations && props?.translations['modal-wrong-code-error-title'])}
                </div>
                <div className="modal-text">
                    {
                        (props.translations && props?.translations['modal-wrong-code-error-message'])
                    }
                </div>
            </>}

            {props?.errorSlug === 'PROMO_CODE_IS_USED'  && <>
                <div className={'modal-title'}>
                    {(props.translations && props?.translations['modal-code-already-used-title'])}
                </div>
                <div className="modal-text">
                    {
                      (props.translations && props?.translations['modal-code-already-used-message'])
                    }
                </div>
            </>}

            {props?.errorSlug === 'TOO_MANY_REQUESTS'  && <>
                <div className={'modal-title'}>
                    {(props.translations && props?.translations['modal-black-listed-title'])}
                </div>
                <div className="modal-text">
                    {props.translations && props?.translations['modal-black-listed-error-message']}
                    <div
                        className="prize-name">{limitTime()} {props.translations && props?.translations['modal-black-listed-minutes-text']}</div>
                </div>
            </>}
            {props?.errorSlug === 'CODE_VALIDATION'  && <>
                <div className={'modal-title'}>
                    {(props.translations && props?.translations['modal-wrong-code-error-title'])}
                </div>
                <div className="modal-text">
                    {
                        (props.translations && props?.translations['modal-wrong-code-error-message'])
                    }
                </div>
            </>}
            {props?.errorSlug === 'UNKNOWN'  && <>
                <div className={'modal-title'}>
                    {(props.translations && props?.translations['modal-general-error-message'])}
                </div>
                <div className="modal-text">
                    {
                        (props.translations && props?.translations['modal-general-error-message'])
                    }
                </div>
            </>}

            <button className='close-button'
                    onClick={closeModal}>
                Փակել
            </button>
        </div>

    </Modal>

}


export default MessageModal;

import axios from "axios";

export const requestWeb = ({url, method = "GET", data = null, code = null,}) => {

    const headers = {
        "Accept-Language": code || "hy"
    };

    const requestData = {
        url: url,
        method: method,
        headers: code ? headers : {},
    };

    data && (requestData.data = data);

    return axios.request(requestData)
};

//--------------------------------------- 4Steps-server --------------------------------------------
// export const WEB_URL = "http://173.249.10.208:3001/api/v1";

//--------------------Admin Production Url-----------------------------------------------------------

export const WEB_URL = "https://api.dilijanbeer.am/front/api/v1";


//--------------------Web Urls-------------------------

export const _urlLanguagesWeb = WEB_URL + "/languages" ;
export const _urlContactsWeb = WEB_URL + "/contact" ;

export const _urlSliderWeb = WEB_URL + "/slider" ;
export const _urlPromoCodeWeb = WEB_URL + "/promo-codes" ;

export const _urlStaticTexts = WEB_URL + "/static-texts" ;
